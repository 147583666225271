import { default as indexbcWslSJ6zvMeta } from "/www/pages/about/index.vue?macro=true";
import { default as seokpHfB3ikBKMeta } from "/www/pages/about/seo.ts?macro=true";
import { default as _91id_93K6CxObBAtqMeta } from "/www/pages/art/[id].vue?macro=true";
import { default as index2PW4nSjWmzMeta } from "/www/pages/art/index.vue?macro=true";
import { default as checkoutqSIU0ZKSvGMeta } from "/www/pages/cart/[type]/checkout.vue?macro=true";
import { default as confirm_orderN6fNKuzYqTMeta } from "/www/pages/cart/confirm_order.vue?macro=true";
import { default as indexz0trIYVSqwMeta } from "/www/pages/cart/index.vue?macro=true";
import { default as ContentylEUnQCgrnMeta } from "/www/pages/competition/[id]/components/Content.vue?macro=true";
import { default as More4ziminM77ZMeta } from "/www/pages/competition/[id]/components/More.vue?macro=true";
import { default as indexTrwVI7Rpg1Meta } from "/www/pages/competition/[id]/index.vue?macro=true";
import { default as ListsoYg6mVyiTCMeta } from "/www/pages/competition/components/Lists.vue?macro=true";
import { default as Slide0rDdJ7OpKNMeta } from "/www/pages/competition/components/Slide.vue?macro=true";
import { default as Videol02Dx4Eh8sMeta } from "/www/pages/competition/components/Video.vue?macro=true";
import { default as indexyj1YUy7EnwMeta } from "/www/pages/competition/index.vue?macro=true";
import { default as indexcps7zy77dBMeta } from "/www/pages/contact/index.vue?macro=true";
import { default as seoAuvWNhCiULMeta } from "/www/pages/contact/seo.ts?macro=true";
import { default as Case21UFx3mJ4cMeta } from "/www/pages/furniture-stories/[id]/components/Case.vue?macro=true";
import { default as ContentOsUl9QuPL8Meta } from "/www/pages/furniture-stories/[id]/components/Content.vue?macro=true";
import { default as More17W6KiOiMIMeta } from "/www/pages/furniture-stories/[id]/components/More.vue?macro=true";
import { default as Productts1r3ThT3nMeta } from "/www/pages/furniture-stories/[id]/components/Product.vue?macro=true";
import { default as indexSNDZSfOi4uMeta } from "/www/pages/furniture-stories/[id]/index.vue?macro=true";
import { default as seoe6puWcyGaXMeta } from "/www/pages/furniture-stories/[id]/seo.ts?macro=true";
import { default as Listsx120mbgZqZMeta } from "/www/pages/furniture-stories/components/Lists.vue?macro=true";
import { default as SlideK0lmBKZxyvMeta } from "/www/pages/furniture-stories/components/Slide.vue?macro=true";
import { default as indexQjWBUgUzNuMeta } from "/www/pages/furniture-stories/index.vue?macro=true";
import { default as seoi94Qpu9AvvMeta } from "/www/pages/furniture-stories/seo.ts?macro=true";
import { default as Section1jyBlRf8LjIMeta } from "/www/pages/index/components/Section1.vue?macro=true";
import { default as Section2ffsVW1WjP4Meta } from "/www/pages/index/components/Section2.vue?macro=true";
import { default as Section3belfS1cHUoMeta } from "/www/pages/index/components/Section3.vue?macro=true";
import { default as Section4XjhZy2iYvqMeta } from "/www/pages/index/components/Section4.vue?macro=true";
import { default as Section5VRvqOf91kHMeta } from "/www/pages/index/components/Section5.vue?macro=true";
import { default as Section6CFJ9xMvk3mMeta } from "/www/pages/index/components/Section6.vue?macro=true";
import { default as Section74DTmfNJvMfMeta } from "/www/pages/index/components/Section7.vue?macro=true";
import { default as Section89KtPgVtg0pMeta } from "/www/pages/index/components/Section8.vue?macro=true";
import { default as indexkKgbZWml8aMeta } from "/www/pages/index/index.vue?macro=true";
import { default as seosUSGgB67pAMeta } from "/www/pages/index/seo.ts?macro=true";
import { default as _91id_93cuxZzWntPtMeta } from "/www/pages/interior-stories/[id].vue?macro=true";
import { default as indexi520lZQdJgMeta } from "/www/pages/interior-stories/index.vue?macro=true";
import { default as _91id_93FUJHbHBQLJMeta } from "/www/pages/issue-detail/[id].vue?macro=true";
import { default as indexttpu5Dj5i2Meta } from "/www/pages/issue-detail/index.vue?macro=true";
import { default as Listsi63EOt0vJlMeta } from "/www/pages/issue/[id]/components/Lists.vue?macro=true";
import { default as indexm8dNtlM5XEMeta } from "/www/pages/issue/[id]/index.vue?macro=true";
import { default as seoR1xu2LYDoYMeta } from "/www/pages/issue/[id]/seo.ts?macro=true";
import { default as ListsFntJAQcuSWMeta } from "/www/pages/issue/components/Lists.vue?macro=true";
import { default as Top4NID9yHapcMeta } from "/www/pages/issue/components/Top.vue?macro=true";
import { default as index83JMY9gzrJMeta } from "/www/pages/issue/index.vue?macro=true";
import { default as seoc61BvvSVvHMeta } from "/www/pages/issue/seo.ts?macro=true";
import { default as Listst9wdScUbozMeta } from "/www/pages/iwcompetition/[id]/components/Lists.vue?macro=true";
import { default as Popup17CtKQgyRRMeta } from "/www/pages/iwcompetition/[id]/components/Popup.vue?macro=true";
import { default as indexldXZN8rJRkMeta } from "/www/pages/iwcompetition/[id]/index.vue?macro=true";
import { default as ContentBTiSZzRbPBMeta } from "/www/pages/iwcompetition/components/Content.vue?macro=true";
import { default as Lists0TbhrPFpipMeta } from "/www/pages/iwcompetition/components/Lists.vue?macro=true";
import { default as Lists2XmhRfLGcbqMeta } from "/www/pages/iwcompetition/components/Lists2.vue?macro=true";
import { default as PopupZEPC8gktfTMeta } from "/www/pages/iwcompetition/components/Popup.vue?macro=true";
import { default as indexIuKdPWwh9TMeta } from "/www/pages/iwcompetition/index.vue?macro=true";
import { default as CasezHC6bmiENpMeta } from "/www/pages/iwselect/[id]/components/Case.vue?macro=true";
import { default as ContentT9ZxJ6dlgBMeta } from "/www/pages/iwselect/[id]/components/Content.vue?macro=true";
import { default as MoreOUnGzdqqtGMeta } from "/www/pages/iwselect/[id]/components/More.vue?macro=true";
import { default as Product9DY9N8PXfRMeta } from "/www/pages/iwselect/[id]/components/Product.vue?macro=true";
import { default as SlideDYnYrXVegiMeta } from "/www/pages/iwselect/[id]/components/Slide.vue?macro=true";
import { default as indexuij5F6pb3WMeta } from "/www/pages/iwselect/[id]/index.vue?macro=true";
import { default as seobiJTw5i1gnMeta } from "/www/pages/iwselect/[id]/seo.ts?macro=true";
import { default as BreadCategoryX6h9xCK8mlMeta } from "/www/pages/iwselect/components/BreadCategory.vue?macro=true";
import { default as Listsr6uFejsgyCMeta } from "/www/pages/iwselect/components/Lists.vue?macro=true";
import { default as NewNlPKISoufiMeta } from "/www/pages/iwselect/components/New.vue?macro=true";
import { default as SideCategory501Y8BCgBCMeta } from "/www/pages/iwselect/components/SideCategory.vue?macro=true";
import { default as TopicjtfWURbjtCMeta } from "/www/pages/iwselect/components/Topic.vue?macro=true";
import { default as indexsd5wzWPUfeMeta } from "/www/pages/iwselect/index.vue?macro=true";
import { default as seo25C8ZHBjWcMeta } from "/www/pages/iwselect/seo.ts?macro=true";
import { default as check_mail_okW8rj4Uim1UMeta } from "/www/pages/member/check_mail_ok.vue?macro=true";
import { default as MemberTabC6aXmzHi9UMeta } from "/www/pages/member/components/MemberTab.vue?macro=true";
import { default as MessageBoxzWD1iAGAorMeta } from "/www/pages/member/components/MessageBox.vue?macro=true";
import { default as NoticeTextTw46gdGBgxMeta } from "/www/pages/member/components/NoticeText.vue?macro=true";
import { default as ProjectSubmitNotice23MqknezfEMeta } from "/www/pages/member/components/ProjectSubmitNotice.vue?macro=true";
import { default as Welcome5gOlyDE1mZMeta } from "/www/pages/member/components/Welcome.vue?macro=true";
import { default as contribute9xYLDZOdYdMeta } from "/www/pages/member/contribute.vue?macro=true";
import { default as designer_memberzWulH0FHsFMeta } from "/www/pages/member/designer_member.vue?macro=true";
import { default as favoriteyqGe9O316iMeta } from "/www/pages/member/favorite.vue?macro=true";
import { default as forgetyWC9t7bmfHMeta } from "/www/pages/member/forget.vue?macro=true";
import { default as historyNUYcFivTA1Meta } from "/www/pages/member/history.vue?macro=true";
import { default as indexAZh4mHJTqcMeta } from "/www/pages/member/index.vue?macro=true";
import { default as loginMC1sJ66SwVMeta } from "/www/pages/member/login.vue?macro=true";
import { default as index9UBS5nwoTLMeta } from "/www/pages/member/order/[id]/index.vue?macro=true";
import { default as indexDMhoQlRkgGMeta } from "/www/pages/member/order/index.vue?macro=true";
import { default as registerSu9kLBGLgQMeta } from "/www/pages/member/register.vue?macro=true";
import { default as seo_forgetmv5SEBnNpQMeta } from "/www/pages/member/seo_forget.ts?macro=true";
import { default as seo_login248Y1km22gMeta } from "/www/pages/member/seo_login.ts?macro=true";
import { default as seo_registerRmOkDk0uwFMeta } from "/www/pages/member/seo_register.ts?macro=true";
import { default as CaseLQU56U5bGtMeta } from "/www/pages/news/[id]/components/Case.vue?macro=true";
import { default as Content0Rtp5Xv92cMeta } from "/www/pages/news/[id]/components/Content.vue?macro=true";
import { default as MoreNO28XMWpR4Meta } from "/www/pages/news/[id]/components/More.vue?macro=true";
import { default as index39nrsA5VBLMeta } from "/www/pages/news/[id]/index.vue?macro=true";
import { default as seoca6jQ0UXyWMeta } from "/www/pages/news/[id]/seo.ts?macro=true";
import { default as ListshTTb0PXrAFMeta } from "/www/pages/news/components/Lists.vue?macro=true";
import { default as indexhL6cyakBP9Meta } from "/www/pages/news/index.vue?macro=true";
import { default as seoFhEvBXdoySMeta } from "/www/pages/news/seo.ts?macro=true";
import { default as CasepKg9l3RmgtMeta } from "/www/pages/opinion/[id]/components/Case.vue?macro=true";
import { default as Content9irMNH9lnlMeta } from "/www/pages/opinion/[id]/components/Content.vue?macro=true";
import { default as More1Ke2gzpgadMeta } from "/www/pages/opinion/[id]/components/More.vue?macro=true";
import { default as indexDgy4062CCtMeta } from "/www/pages/opinion/[id]/index.vue?macro=true";
import { default as seoGDKX8oxAp0Meta } from "/www/pages/opinion/[id]/seo.ts?macro=true";
import { default as ListsmlQMgRhdXWMeta } from "/www/pages/opinion/components/Lists.vue?macro=true";
import { default as indexIAyit1z4p8Meta } from "/www/pages/opinion/index.vue?macro=true";
import { default as seo2ZWb1SCcfvMeta } from "/www/pages/opinion/seo.ts?macro=true";
import { default as indexi05OT7PkOyMeta } from "/www/pages/privacy/index.vue?macro=true";
import { default as seoPneasBnut2Meta } from "/www/pages/privacy/seo.ts?macro=true";
import { default as _91id_93XJ7v8AZLD2Meta } from "/www/pages/product-stories/[id].vue?macro=true";
import { default as indexe1TjZAEyclMeta } from "/www/pages/product-stories/index.vue?macro=true";
import { default as Case05X4Ej4hNkMeta } from "/www/pages/scope/[id]/components/Case.vue?macro=true";
import { default as Contentj2QjzMC4ysMeta } from "/www/pages/scope/[id]/components/Content.vue?macro=true";
import { default as Moreb1GrnhbKVMMeta } from "/www/pages/scope/[id]/components/More.vue?macro=true";
import { default as indexBbo58h2P2FMeta } from "/www/pages/scope/[id]/index.vue?macro=true";
import { default as seo5U6Ud9icIIMeta } from "/www/pages/scope/[id]/seo.ts?macro=true";
import { default as CategoryoL263W7pAXMeta } from "/www/pages/scope/components/Category.vue?macro=true";
import { default as Featuredt985Llyyr3Meta } from "/www/pages/scope/components/Featured.vue?macro=true";
import { default as ListsC3gjQw0YpaMeta } from "/www/pages/scope/components/Lists.vue?macro=true";
import { default as NewzYa7rRzEf3Meta } from "/www/pages/scope/components/New.vue?macro=true";
import { default as SlideQyWdxUtBCEMeta } from "/www/pages/scope/components/Slide.vue?macro=true";
import { default as TopicOezGS8S18fMeta } from "/www/pages/scope/components/Topic.vue?macro=true";
import { default as indexVvDE2NmJmCMeta } from "/www/pages/scope/index.vue?macro=true";
import { default as seoYYLr2IEy95Meta } from "/www/pages/scope/seo.ts?macro=true";
import { default as SearchtWAcS3j84vMeta } from "/www/pages/search/components/Search.vue?macro=true";
import { default as SearchTagScL0b8PUfdMeta } from "/www/pages/search/components/SearchTag.vue?macro=true";
import { default as Video4XO3xfB7zsMeta } from "/www/pages/search/components/Video.vue?macro=true";
import { default as indexYP6TMEMuQzMeta } from "/www/pages/search/index.vue?macro=true";
import { default as seorLMIUcFIzdMeta } from "/www/pages/search/seo.ts?macro=true";
import { default as indext7zorva0CbMeta } from "/www/pages/shipping/index.vue?macro=true";
import { default as seoMbYuQ0WeFcMeta } from "/www/pages/shipping/seo.ts?macro=true";
import { default as _91id_93V8EEhWpPCTMeta } from "/www/pages/space/[id].vue?macro=true";
import { default as indexGlj7sC1aOEMeta } from "/www/pages/space/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/www/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about-seo",
    path: "/about/seo",
    component: () => import("/www/pages/about/seo.ts").then(m => m.default || m)
  },
  {
    name: "art-id",
    path: "/art/:id()",
    component: () => import("/www/pages/art/[id].vue").then(m => m.default || m)
  },
  {
    name: "art",
    path: "/art",
    component: () => import("/www/pages/art/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-type-checkout",
    path: "/cart/:type()/checkout",
    component: () => import("/www/pages/cart/[type]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cart-confirm_order",
    path: "/cart/confirm_order",
    component: () => import("/www/pages/cart/confirm_order.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/www/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "competition-id-components-Content",
    path: "/competition/:id()/components/Content",
    component: () => import("/www/pages/competition/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: "competition-id-components-More",
    path: "/competition/:id()/components/More",
    component: () => import("/www/pages/competition/[id]/components/More.vue").then(m => m.default || m)
  },
  {
    name: "competition-id",
    path: "/competition/:id()",
    component: () => import("/www/pages/competition/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "competition-components-Lists",
    path: "/competition/components/Lists",
    component: () => import("/www/pages/competition/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "competition-components-Slide",
    path: "/competition/components/Slide",
    component: () => import("/www/pages/competition/components/Slide.vue").then(m => m.default || m)
  },
  {
    name: "competition-components-Video",
    path: "/competition/components/Video",
    component: () => import("/www/pages/competition/components/Video.vue").then(m => m.default || m)
  },
  {
    name: "competition",
    path: "/competition",
    component: () => import("/www/pages/competition/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/www/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-seo",
    path: "/contact/seo",
    component: () => import("/www/pages/contact/seo.ts").then(m => m.default || m)
  },
  {
    name: "furniture-stories-id-components-Case",
    path: "/furniture-stories/:id()/components/Case",
    component: () => import("/www/pages/furniture-stories/[id]/components/Case.vue").then(m => m.default || m)
  },
  {
    name: "furniture-stories-id-components-Content",
    path: "/furniture-stories/:id()/components/Content",
    component: () => import("/www/pages/furniture-stories/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: "furniture-stories-id-components-More",
    path: "/furniture-stories/:id()/components/More",
    component: () => import("/www/pages/furniture-stories/[id]/components/More.vue").then(m => m.default || m)
  },
  {
    name: "furniture-stories-id-components-Product",
    path: "/furniture-stories/:id()/components/Product",
    component: () => import("/www/pages/furniture-stories/[id]/components/Product.vue").then(m => m.default || m)
  },
  {
    name: "furniture-stories-id",
    path: "/furniture-stories/:id()",
    component: () => import("/www/pages/furniture-stories/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "furniture-stories-id-seo",
    path: "/furniture-stories/:id()/seo",
    component: () => import("/www/pages/furniture-stories/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: "furniture-stories-components-Lists",
    path: "/furniture-stories/components/Lists",
    component: () => import("/www/pages/furniture-stories/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "furniture-stories-components-Slide",
    path: "/furniture-stories/components/Slide",
    component: () => import("/www/pages/furniture-stories/components/Slide.vue").then(m => m.default || m)
  },
  {
    name: "furniture-stories",
    path: "/furniture-stories",
    component: () => import("/www/pages/furniture-stories/index.vue").then(m => m.default || m)
  },
  {
    name: "furniture-stories-seo",
    path: "/furniture-stories/seo",
    component: () => import("/www/pages/furniture-stories/seo.ts").then(m => m.default || m)
  },
  {
    name: "index-components-Section1",
    path: "//components/Section1",
    component: () => import("/www/pages/index/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: "index-components-Section2",
    path: "//components/Section2",
    component: () => import("/www/pages/index/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: "index-components-Section3",
    path: "//components/Section3",
    component: () => import("/www/pages/index/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: "index-components-Section4",
    path: "//components/Section4",
    component: () => import("/www/pages/index/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: "index-components-Section5",
    path: "//components/Section5",
    component: () => import("/www/pages/index/components/Section5.vue").then(m => m.default || m)
  },
  {
    name: "index-components-Section6",
    path: "//components/Section6",
    component: () => import("/www/pages/index/components/Section6.vue").then(m => m.default || m)
  },
  {
    name: "index-components-Section7",
    path: "//components/Section7",
    component: () => import("/www/pages/index/components/Section7.vue").then(m => m.default || m)
  },
  {
    name: "index-components-Section8",
    path: "//components/Section8",
    component: () => import("/www/pages/index/components/Section8.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/www/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "index-seo",
    path: "//seo",
    component: () => import("/www/pages/index/seo.ts").then(m => m.default || m)
  },
  {
    name: "interior-stories-id",
    path: "/interior-stories/:id()",
    component: () => import("/www/pages/interior-stories/[id].vue").then(m => m.default || m)
  },
  {
    name: "interior-stories",
    path: "/interior-stories",
    component: () => import("/www/pages/interior-stories/index.vue").then(m => m.default || m)
  },
  {
    name: "issue-detail-id",
    path: "/issue-detail/:id()",
    component: () => import("/www/pages/issue-detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "issue-detail",
    path: "/issue-detail",
    component: () => import("/www/pages/issue-detail/index.vue").then(m => m.default || m)
  },
  {
    name: "issue-id-components-Lists",
    path: "/issue/:id()/components/Lists",
    component: () => import("/www/pages/issue/[id]/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "issue-id",
    path: "/issue/:id()",
    component: () => import("/www/pages/issue/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "issue-id-seo",
    path: "/issue/:id()/seo",
    component: () => import("/www/pages/issue/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: "issue-components-Lists",
    path: "/issue/components/Lists",
    component: () => import("/www/pages/issue/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "issue-components-Top",
    path: "/issue/components/Top",
    component: () => import("/www/pages/issue/components/Top.vue").then(m => m.default || m)
  },
  {
    name: "issue",
    path: "/issue",
    component: () => import("/www/pages/issue/index.vue").then(m => m.default || m)
  },
  {
    name: "issue-seo",
    path: "/issue/seo",
    component: () => import("/www/pages/issue/seo.ts").then(m => m.default || m)
  },
  {
    name: "iwcompetition-id-components-Lists",
    path: "/iwcompetition/:id()/components/Lists",
    component: () => import("/www/pages/iwcompetition/[id]/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "iwcompetition-id-components-Popup",
    path: "/iwcompetition/:id()/components/Popup",
    component: () => import("/www/pages/iwcompetition/[id]/components/Popup.vue").then(m => m.default || m)
  },
  {
    name: "iwcompetition-id",
    path: "/iwcompetition/:id()",
    component: () => import("/www/pages/iwcompetition/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "iwcompetition-components-Content",
    path: "/iwcompetition/components/Content",
    component: () => import("/www/pages/iwcompetition/components/Content.vue").then(m => m.default || m)
  },
  {
    name: "iwcompetition-components-Lists",
    path: "/iwcompetition/components/Lists",
    component: () => import("/www/pages/iwcompetition/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "iwcompetition-components-Lists2",
    path: "/iwcompetition/components/Lists2",
    component: () => import("/www/pages/iwcompetition/components/Lists2.vue").then(m => m.default || m)
  },
  {
    name: "iwcompetition-components-Popup",
    path: "/iwcompetition/components/Popup",
    component: () => import("/www/pages/iwcompetition/components/Popup.vue").then(m => m.default || m)
  },
  {
    name: "iwcompetition",
    path: "/iwcompetition",
    component: () => import("/www/pages/iwcompetition/index.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-id-components-Case",
    path: "/iwselect/:id()/components/Case",
    component: () => import("/www/pages/iwselect/[id]/components/Case.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-id-components-Content",
    path: "/iwselect/:id()/components/Content",
    component: () => import("/www/pages/iwselect/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-id-components-More",
    path: "/iwselect/:id()/components/More",
    component: () => import("/www/pages/iwselect/[id]/components/More.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-id-components-Product",
    path: "/iwselect/:id()/components/Product",
    component: () => import("/www/pages/iwselect/[id]/components/Product.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-id-components-Slide",
    path: "/iwselect/:id()/components/Slide",
    component: () => import("/www/pages/iwselect/[id]/components/Slide.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-id",
    path: "/iwselect/:id()",
    component: () => import("/www/pages/iwselect/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-id-seo",
    path: "/iwselect/:id()/seo",
    component: () => import("/www/pages/iwselect/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: "iwselect-components-BreadCategory",
    path: "/iwselect/components/BreadCategory",
    component: () => import("/www/pages/iwselect/components/BreadCategory.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-components-Lists",
    path: "/iwselect/components/Lists",
    component: () => import("/www/pages/iwselect/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-components-New",
    path: "/iwselect/components/New",
    component: () => import("/www/pages/iwselect/components/New.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-components-SideCategory",
    path: "/iwselect/components/SideCategory",
    component: () => import("/www/pages/iwselect/components/SideCategory.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-components-Topic",
    path: "/iwselect/components/Topic",
    component: () => import("/www/pages/iwselect/components/Topic.vue").then(m => m.default || m)
  },
  {
    name: "iwselect",
    path: "/iwselect",
    component: () => import("/www/pages/iwselect/index.vue").then(m => m.default || m)
  },
  {
    name: "iwselect-seo",
    path: "/iwselect/seo",
    component: () => import("/www/pages/iwselect/seo.ts").then(m => m.default || m)
  },
  {
    name: "member-check_mail_ok",
    path: "/member/check_mail_ok",
    component: () => import("/www/pages/member/check_mail_ok.vue").then(m => m.default || m)
  },
  {
    name: "member-components-MemberTab",
    path: "/member/components/MemberTab",
    component: () => import("/www/pages/member/components/MemberTab.vue").then(m => m.default || m)
  },
  {
    name: "member-components-MessageBox",
    path: "/member/components/MessageBox",
    component: () => import("/www/pages/member/components/MessageBox.vue").then(m => m.default || m)
  },
  {
    name: "member-components-NoticeText",
    path: "/member/components/NoticeText",
    component: () => import("/www/pages/member/components/NoticeText.vue").then(m => m.default || m)
  },
  {
    name: "member-components-ProjectSubmitNotice",
    path: "/member/components/ProjectSubmitNotice",
    component: () => import("/www/pages/member/components/ProjectSubmitNotice.vue").then(m => m.default || m)
  },
  {
    name: "member-components-Welcome",
    path: "/member/components/Welcome",
    component: () => import("/www/pages/member/components/Welcome.vue").then(m => m.default || m)
  },
  {
    name: "member-contribute",
    path: "/member/contribute",
    meta: contribute9xYLDZOdYdMeta || {},
    component: () => import("/www/pages/member/contribute.vue").then(m => m.default || m)
  },
  {
    name: "member-designer_member",
    path: "/member/designer_member",
    meta: designer_memberzWulH0FHsFMeta || {},
    component: () => import("/www/pages/member/designer_member.vue").then(m => m.default || m)
  },
  {
    name: "member-favorite",
    path: "/member/favorite",
    meta: favoriteyqGe9O316iMeta || {},
    component: () => import("/www/pages/member/favorite.vue").then(m => m.default || m)
  },
  {
    name: "member-forget",
    path: "/member/forget",
    component: () => import("/www/pages/member/forget.vue").then(m => m.default || m)
  },
  {
    name: "member-history",
    path: "/member/history",
    meta: historyNUYcFivTA1Meta || {},
    component: () => import("/www/pages/member/history.vue").then(m => m.default || m)
  },
  {
    name: "member",
    path: "/member",
    meta: indexAZh4mHJTqcMeta || {},
    component: () => import("/www/pages/member/index.vue").then(m => m.default || m)
  },
  {
    name: "member-login",
    path: "/member/login",
    component: () => import("/www/pages/member/login.vue").then(m => m.default || m)
  },
  {
    name: "member-order-id",
    path: "/member/order/:id()",
    meta: index9UBS5nwoTLMeta || {},
    component: () => import("/www/pages/member/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "member-order",
    path: "/member/order",
    meta: indexDMhoQlRkgGMeta || {},
    component: () => import("/www/pages/member/order/index.vue").then(m => m.default || m)
  },
  {
    name: "member-register",
    path: "/member/register",
    component: () => import("/www/pages/member/register.vue").then(m => m.default || m)
  },
  {
    name: "member-seo_forget",
    path: "/member/seo_forget",
    component: () => import("/www/pages/member/seo_forget.ts").then(m => m.default || m)
  },
  {
    name: "member-seo_login",
    path: "/member/seo_login",
    component: () => import("/www/pages/member/seo_login.ts").then(m => m.default || m)
  },
  {
    name: "member-seo_register",
    path: "/member/seo_register",
    component: () => import("/www/pages/member/seo_register.ts").then(m => m.default || m)
  },
  {
    name: "news-id-components-Case",
    path: "/news/:id()/components/Case",
    component: () => import("/www/pages/news/[id]/components/Case.vue").then(m => m.default || m)
  },
  {
    name: "news-id-components-Content",
    path: "/news/:id()/components/Content",
    component: () => import("/www/pages/news/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: "news-id-components-More",
    path: "/news/:id()/components/More",
    component: () => import("/www/pages/news/[id]/components/More.vue").then(m => m.default || m)
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/www/pages/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "news-id-seo",
    path: "/news/:id()/seo",
    component: () => import("/www/pages/news/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: "news-components-Lists",
    path: "/news/components/Lists",
    component: () => import("/www/pages/news/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/www/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news-seo",
    path: "/news/seo",
    component: () => import("/www/pages/news/seo.ts").then(m => m.default || m)
  },
  {
    name: "opinion-id-components-Case",
    path: "/opinion/:id()/components/Case",
    component: () => import("/www/pages/opinion/[id]/components/Case.vue").then(m => m.default || m)
  },
  {
    name: "opinion-id-components-Content",
    path: "/opinion/:id()/components/Content",
    component: () => import("/www/pages/opinion/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: "opinion-id-components-More",
    path: "/opinion/:id()/components/More",
    component: () => import("/www/pages/opinion/[id]/components/More.vue").then(m => m.default || m)
  },
  {
    name: "opinion-id",
    path: "/opinion/:id()",
    component: () => import("/www/pages/opinion/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "opinion-id-seo",
    path: "/opinion/:id()/seo",
    component: () => import("/www/pages/opinion/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: "opinion-components-Lists",
    path: "/opinion/components/Lists",
    component: () => import("/www/pages/opinion/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "opinion",
    path: "/opinion",
    component: () => import("/www/pages/opinion/index.vue").then(m => m.default || m)
  },
  {
    name: "opinion-seo",
    path: "/opinion/seo",
    component: () => import("/www/pages/opinion/seo.ts").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/www/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-seo",
    path: "/privacy/seo",
    component: () => import("/www/pages/privacy/seo.ts").then(m => m.default || m)
  },
  {
    name: "product-stories-id",
    path: "/product-stories/:id()",
    component: () => import("/www/pages/product-stories/[id].vue").then(m => m.default || m)
  },
  {
    name: "product-stories",
    path: "/product-stories",
    component: () => import("/www/pages/product-stories/index.vue").then(m => m.default || m)
  },
  {
    name: "scope-id-components-Case",
    path: "/scope/:id()/components/Case",
    component: () => import("/www/pages/scope/[id]/components/Case.vue").then(m => m.default || m)
  },
  {
    name: "scope-id-components-Content",
    path: "/scope/:id()/components/Content",
    component: () => import("/www/pages/scope/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: "scope-id-components-More",
    path: "/scope/:id()/components/More",
    component: () => import("/www/pages/scope/[id]/components/More.vue").then(m => m.default || m)
  },
  {
    name: "scope-id",
    path: "/scope/:id()",
    component: () => import("/www/pages/scope/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "scope-id-seo",
    path: "/scope/:id()/seo",
    component: () => import("/www/pages/scope/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: "scope-components-Category",
    path: "/scope/components/Category",
    component: () => import("/www/pages/scope/components/Category.vue").then(m => m.default || m)
  },
  {
    name: "scope-components-Featured",
    path: "/scope/components/Featured",
    component: () => import("/www/pages/scope/components/Featured.vue").then(m => m.default || m)
  },
  {
    name: "scope-components-Lists",
    path: "/scope/components/Lists",
    component: () => import("/www/pages/scope/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: "scope-components-New",
    path: "/scope/components/New",
    component: () => import("/www/pages/scope/components/New.vue").then(m => m.default || m)
  },
  {
    name: "scope-components-Slide",
    path: "/scope/components/Slide",
    component: () => import("/www/pages/scope/components/Slide.vue").then(m => m.default || m)
  },
  {
    name: "scope-components-Topic",
    path: "/scope/components/Topic",
    component: () => import("/www/pages/scope/components/Topic.vue").then(m => m.default || m)
  },
  {
    name: "scope",
    path: "/scope",
    component: () => import("/www/pages/scope/index.vue").then(m => m.default || m)
  },
  {
    name: "scope-seo",
    path: "/scope/seo",
    component: () => import("/www/pages/scope/seo.ts").then(m => m.default || m)
  },
  {
    name: "search-components-Search",
    path: "/search/components/Search",
    component: () => import("/www/pages/search/components/Search.vue").then(m => m.default || m)
  },
  {
    name: "search-components-SearchTag",
    path: "/search/components/SearchTag",
    component: () => import("/www/pages/search/components/SearchTag.vue").then(m => m.default || m)
  },
  {
    name: "search-components-Video",
    path: "/search/components/Video",
    component: () => import("/www/pages/search/components/Video.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/www/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search-seo",
    path: "/search/seo",
    component: () => import("/www/pages/search/seo.ts").then(m => m.default || m)
  },
  {
    name: "shipping",
    path: "/shipping",
    component: () => import("/www/pages/shipping/index.vue").then(m => m.default || m)
  },
  {
    name: "shipping-seo",
    path: "/shipping/seo",
    component: () => import("/www/pages/shipping/seo.ts").then(m => m.default || m)
  },
  {
    name: "space-id",
    path: "/space/:id()",
    component: () => import("/www/pages/space/[id].vue").then(m => m.default || m)
  },
  {
    name: "space",
    path: "/space",
    component: () => import("/www/pages/space/index.vue").then(m => m.default || m)
  }
]